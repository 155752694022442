import axios from '@/libs/axios';
import HelperService from "@/services/HelperService";

const baseUrl = '/kiosk-announcements';

export default {
  list(filters) {
    const queryString = HelperService.buildSearchQueryString(filters);
    return axios.get(`${baseUrl}${queryString}`);
  },
  createKioskAnnouncement(announcementDto) {
    return axios.post(baseUrl, announcementDto);
  },
  updateKioskAnnouncement(id, announcementDto) {
    return axios.put(`${baseUrl}/${id.toString()}`, announcementDto);
  },
  deleteAnnouncement(id) {
    return axios.delete(`${baseUrl}/${id.toString()}`);
  }
}
