<script>
import helperService from "@/services/HelperService";
import KioskAnnouncementsService from "@/services/KioskAnnouncementsService";

export default {
  name: 'KioskAnnouncementModal',
  components: {},
  props: {
    announcement: {
      type: Object,
      default: () => null,
    }
  },
  data() {
    return {
      loading: false,
      announcementForm: {
        title: '',
        body: '',
        active: true,
        start_date: '',
        end_date: '',
      },
    }
  },
  computed: {
    updating() {
      return this.announcement;
    },
  },
  watch: {
    announcement: {
      handler(newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.announcementForm = { ...newVal };
        }
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    getValidationState({ dirty, validated, valid = null }) {
      return dirty || validated ? valid : null;
    },
    async submitKioskAnnouncement() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const announcementDto = {
        title: this.announcementForm.title,
        body: this.announcementForm.body,
        active: this.announcementForm.active,
        start_date: new Date(this.announcementForm.start_date).toISOString(),
        end_date: new Date(this.announcementForm.end_date).toISOString(),
      }

      try {
        if (!this.updating) {
          await KioskAnnouncementsService.createKioskAnnouncement(announcementDto);
        } else {
          await KioskAnnouncementsService.updateKioskAnnouncement(this.announcement.id, announcementDto);
        }
        helperService.showNotfySuccess(this.$toast, `Announcement successfully ${this.updating ? 'updated' : 'created'}.`)
      } catch (err) {
        const msg = `Error ${this.updating ? 'updating' : 'creating'} announcement`
        // console.error(msg, err)
        helperService.showNotfyErr(this.$toast, err, msg + err)
      } finally {
        this.loading = false;
        this.$emit('refresh');
      }
    },
    async deleteAnnouncement() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      try {
        await KioskAnnouncementsService.deleteAnnouncement(this.announcement.id);
        this.$emit('refresh');
        helperService.showNotfySuccess(this.$toast, 'Announcement successfully deleted.');
      } catch (err) {
        // console.error('Error deleting announcement: ', err);
        helperService.showNotfyErr(this.$toast, 'Error deleting announcement, please try again.', err);
      } finally {
        this.loading = false;
      }
    }
  },
}
</script>

<template>
  <div>
    <section v-if="!loading">
      <validation-observer ref="observer" v-slot="{ handleSubmit, invalid }">
        <b-form @submit.stop.prevent="handleSubmit(submitKioskAnnouncement)">
          <b-form-group>
            <!--Title-->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                Title
              </label>
              <validation-provider
                v-slot="validationContext"
                name="title"
                :rules="{required: true}"
              >
                <b-form-input
                  v-model="announcementForm.title"
                  placeholder="Enter an announcement title..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!--Body-->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                Body
              </label>

              <validation-provider
                v-slot="validationContext"
                name="body"
                :rules="{required: true}"
              >
                <b-form-textarea
                  v-model="announcementForm.body"
                  placeholder="Enter an announcement body..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!--Active Status Dropdown-->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>Active Status</label>
              <b-form-select v-model="announcementForm.active" class="w-100">
                <b-form-select-option :value="true">Active</b-form-select-option>
                <b-form-select-option :value="false">Inactive</b-form-select-option>
              </b-form-select>
            </b-input-group>

            <!-- Start date -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                Start Date
              </label>

              <validation-provider
                v-slot="validationContext"
                name="body"
                :rules="{required: true}"
              >
                <b-form-datepicker
                  v-model="announcementForm.start_date"
                  placeholder="Enter an start date..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!-- End date -->
            <b-input-group class="mb-1 d-flex flex-column mt-1">
              <label>
                End Date
              </label>

              <validation-provider
                v-slot="validationContext"
                name="body"
                :rules="{required: true}"
              >
                <b-form-datepicker
                  v-model="announcementForm.end_date"
                  placeholder="Enter an end date..."
                  :state="getValidationState(validationContext)"
                />
              </validation-provider>
            </b-input-group>

            <!--Button-->
            <b-input-group class="d-flex flex-row-reverse mt-3">
              <b-btn variant="primary" type="submit" :disabled="invalid">
                {{ updating ? 'Update ' : 'Create ' }}
              </b-btn>
              <b-btn v-if="updating && $can('Delete', 'KioskAnnouncements')" variant="danger" class="d-inline mr-1" :disabled="invalid" @click="deleteAnnouncement">
                Delete
              </b-btn>
              <b-btn class="d-inline mr-1" @click="$emit('close')">
                Cancel
              </b-btn>
            </b-input-group>
          </b-form-group>
        </b-form>
      </validation-observer>
    </section>
  </div>
</template>
